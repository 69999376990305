import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ablobthinking from "../images/ablobthinking.gif"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <article className="container mx-auto h-full flex flex-col items-center justify-center">
      <h1 className="p-4 text-5xl">404: Not Found</h1>
      <p>お探しのページは見つかりませんでした</p>
      <img src={ablobthinking} alt="404" />
    </article>
  </Layout>
)

export default NotFoundPage
